import React from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import StyledInput from '../components/StyledInput'
import StyledButton from '../components/StyledButton'
import styled from 'styled-components'
import { App, Form } from 'antd'
import supabase from '../service/supabase'

interface Props {}

interface Form {
  password: string
  passwordConfirm: string
}

const PasswordEditPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { message } = App.useApp()

  const [form, setForm] = React.useState<Form>({
    password: '',
    passwordConfirm: '',
  })
  const [isShowValidation, setIsShowValidation] = React.useState(false)
  const [isInvalidPasswordConfirm, setIsInvalidPasswordConfirm] =
    React.useState(true)

  const onClickSignupButton = async () => {
    setIsShowValidation(true)
    if (!form.password || !form.passwordConfirm || isInvalidPasswordConfirm) {
      return
    }

    const { error } = await supabase.auth.updateUser({
      password: form.password,
    })

    if (error) {
      message.error('Failed: ' + error.message)
      return
    }

    message.success('비밀번호 수정이 완료되었습니다.')
    navigate('/setting')
  }

  return (
    <>
      <Header
        title="비밀번호 수정"
        onClickBackButton={() => {
          navigate('/setting')
        }}
      />

      <StyledSignupPage>
        <Form layout="vertical">
          <Form.Item
            validateStatus={
              isShowValidation && !form.password ? 'error' : 'success'
            }
          >
            <StyledInput
              value={form.password}
              type="password"
              placeholder="비밀번호"
              onChange={(e) => {
                const password = e.target.value
                setIsInvalidPasswordConfirm(form.passwordConfirm !== password)
                setForm({ ...form, password: e.target.value })
              }}
            />
          </Form.Item>

          <Form.Item
            validateStatus={
              isShowValidation &&
              (!form.passwordConfirm || isInvalidPasswordConfirm)
                ? 'error'
                : 'success'
            }
            help={
              isShowValidation && isInvalidPasswordConfirm
                ? '비밀번호가 일치하지 않습니다.'
                : ''
            }
          >
            <StyledInput
              value={form.passwordConfirm}
              type="password"
              placeholder="비밀번호 확인"
              onChange={(e) => {
                const passwordConfirm = e.target.value
                setIsInvalidPasswordConfirm(form.password !== passwordConfirm)
                setForm({ ...form, passwordConfirm: passwordConfirm })
              }}
            />
          </Form.Item>

          <StyledButton type="primary" block onClick={onClickSignupButton}>
            비밀번호 수정
          </StyledButton>
        </Form>
      </StyledSignupPage>
    </>
  )
}

const StyledSignupPage = styled.div`
  padding: 32px;
  margin-top: 56px;
  overflow: scroll;
  height: calc(100vh - 80px);
`

export default PasswordEditPage
