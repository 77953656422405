import { Input as AntdInput, InputProps, InputRef } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledInput: React.FC<InputProps & React.RefAttributes<InputRef>> = (
  props,
) => {
  return <Input {...props} />
}

const Input = styled(AntdInput)`
  padding: 10px 12px;
`

export default StyledInput
