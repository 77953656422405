import React, { useContext } from 'react'
import Header from '../components/Header'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../components/AppContext'
import PageContainer from '../components/PageContainer'
import styled from 'styled-components'

interface Props {}

const SettingPage: React.FC<Props> = () => {
  const { updateUser } = useContext(AppContext)
  const navigate = useNavigate()

  return (
    <>
      <Header onClickBackButton={() => navigate('/')} title="설정" />
      <PageContainer>
        <SettingPageContainer>
          <div
            onClick={() => {
              navigate('/setting/profile')
            }}
          >
            정보 수정
          </div>
          <div
            onClick={() => {
              navigate('/setting/password')
            }}
          >
            비밀번호 수정
          </div>
          <div
            onClick={() => {
              updateUser({ id: '', companyName: '' })
              navigate('/login')
            }}
          >
            로그아웃
          </div>
        </SettingPageContainer>
      </PageContainer>
    </>
  )
}

const SettingPageContainer = styled.div`
  margin: -16px;

  div {
    padding: 16px;
    cursor: pointer;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e0e0e0;
  }
`

export default SettingPage
