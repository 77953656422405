import { GetSupplyInfoReportSummary } from '../models/interfaces'
import { ReportForm } from '../pages/ReportRegisterPage/components/data'
import { androidDemoId, demoPassword, iosDemoId } from './demo'
import supabase, { productionClientKey } from './supabase'

const SUPPLY_INFO_MANAGES = 'supplyInfoManages'
const SUPPLY_INFO_REPORTS = 'supplyInfoReports'
const SUPPLY_INFO_REPORT_SUMMARY = 'supplyInfoReportSummary'

export const login = async (id: string, password: string) => {
  if (id === androidDemoId || id === iosDemoId) {
    if (password !== demoPassword) {
      return { error: new Error('잘못된 비밀번호입니다.') }
    }

    setDemoInitialData()

    const data = {
      user: {
        id,
        user_metadata: {
          companyName: 'DEMO',
        },
      },
    }

    return {
      data,
      error: null,
    }
  }

  return await supabase.auth.signInWithPassword({
    email: `${id}@user.udipass.optimwork`,
    password,
  })
}

export const getSupplyInfoUdidiProduct = async (
  userId: string,
  params: { udiDiCode: string },
) => {
  if (isDemo(userId)) {
    return {
      data: {
        items: [
          {
            meddevItemSeq: 2023001425,
            seq: 1519980,
            udiDiSeq: 3328137,
            udiDiCode: '08800201600038',
            entpName: '주식회사 리젠바이오',
            itemName: '콜라겐사용조직보충재 ',
            meaClassNo: 'B04280.01',
            permitNo: '제허 23-320 호',
            packQuantity: 1,
            typeName: 'IC30A10',
            useLotNo: true,
            useItemSeq: null,
            useManufYm: null,
            useTimeLimit: true,
            cobTypeName: '제조업',
            grade: '4',
            brandName: '이엔콜(ENcol), 인콜(INcol), 이엔케어(ENcare)',
            userSterilizationYn: false,
            kitYn: false,
            isRcperSalaryTarget: false,
            rcperSalaryCd: null,
            isUseEnd: false,
            useEndReason: null,
            useEndDate: null,
            isTmngTrgt: false,
            rcperSalaryTypeNm: null,
            codeSystemFlag: 'GS1',
            mxUnprc: 3950,
          },
        ],
      },
      error: null,
    }
  }

  return await supabase.functions.invoke('getSupplyInfoUdidiProduct', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

export const getSupplyInfoManages = async (
  userId: string,
  params: { suplyContStdmt: string; bcncName?: string; offset: number },
) => {
  if (isDemo(userId)) {
    const data = JSON.parse(localStorage.getItem(SUPPLY_INFO_MANAGES) ?? '')

    return { data, error: null }
  }

  return await supabase.functions.invoke('getSupplyInfoManages', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

export const getCompanyNames = async (params: { bizRegNumber: string }) => {
  return await fetch(
    `https://ozp74z4oxtduppw2cveizg6zby0qhyxo.lambda-url.ap-northeast-2.on.aws`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    },
  )
}

export const postSupplyInfoManages = async (
  userId: string,
  params: ReportForm,
) => {
  if (isDemo(userId)) {
    const existingDataString = JSON.parse(
      localStorage.getItem(SUPPLY_INFO_MANAGES) ?? '',
    )

    localStorage.setItem(
      SUPPLY_INFO_MANAGES,
      JSON.stringify({
        ...existingDataString,
        items: [
          {
            suplyContSeq: Math.random(),
            suplyDate: params.suplyDate,
            itemName: '주사기',
            dvyfgEntpName: 'DEMO hospital',
            grade: '1',
            suplyQty: params.suplyQty,
            suplyAmt: params.suplyUntpc,
          },
          ...existingDataString.items,
        ],
      }),
    )

    return { error: null }
  }

  return await supabase.functions.invoke('postSupplyInfoManages', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

export const deleteSupplyInfoManages = async (
  userId: string,
  params: {
    suplyContStdmt: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    suplyContSeq: any
  },
) => {
  if (isDemo(userId)) {
    const existingDataString = JSON.parse(
      localStorage.getItem(SUPPLY_INFO_MANAGES) ?? '',
    )

    localStorage.setItem(
      SUPPLY_INFO_MANAGES,
      JSON.stringify({
        ...existingDataString,
        items: existingDataString.items.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (item: any) => item.suplyContSeq !== params.suplyContSeq,
        ),
      }),
    )

    return { error: null }
  }

  return await supabase.functions.invoke('deleteSupplyInfoManages', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

export const postSupplyInfoManagesReport = async (
  userId: string,
  params: {
    suplyContStdmt: string
  },
) => {
  if (isDemo(userId)) {
    const existingDataString = localStorage.getItem(SUPPLY_INFO_REPORT_SUMMARY)
    const data = JSON.parse(existingDataString ?? '')

    const items = data.items.map((item: GetSupplyInfoReportSummary) => {
      if (item.suplyContStdmt === params.suplyContStdmt) {
        return {
          ...item,
          reportStatus: item.reportStatus === '보고' ? '보고취소' : '보고',
        }
      }

      return item
    })

    localStorage.setItem(
      SUPPLY_INFO_REPORT_SUMMARY,
      JSON.stringify({
        ...data,
        items,
      }),
    )

    return { error: null }
  }

  return await supabase.functions.invoke('postSupplyInfoManagesReport', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

export const getCompanyInfoBcnc = async (
  userId: string,
  params: { companyName: string },
) => {
  if (isDemo(userId)) {
    return {
      data: {
        page: 1,
        pageSize: 100,
        totalElements: 10,
        items: [
          {
            no: '1',
            bcncUnityCompanySeqs: null,
            bcncCode: '1082049710000108',
            companyName: '인터넷구매',
            bossName: null,
            bcncCobFlagCodeNm: '기타',
            bcncCobDetailName: null,
            hptlSymbl: null,
            area: '서울',
            entpAddr: '서울 성동구 마장로 260 ',
            entpAddr1: '서울 성동구 마장로 260',
            entpAddr2: null,
            taxNo: '872-86-00715',
          },
          {
            no: '2',
            bcncUnityCompanySeqs: null,
            bcncCode: '1082049710000107',
            companyName: '환자',
            bossName: null,
            bcncCobFlagCodeNm: '기타',
            bcncCobDetailName: null,
            hptlSymbl: null,
            area: '서울',
            entpAddr: '서울 성동구 마장로 260 ',
            entpAddr1: '서울 성동구 마장로 260',
            entpAddr2: null,
            taxNo: '872-86-00715',
          },
          {
            no: '3',
            bcncUnityCompanySeqs: null,
            bcncCode: '1082049710000106',
            companyName: '인터넷구매',
            bossName: null,
            bcncCobFlagCodeNm: '기타',
            bcncCobDetailName: null,
            hptlSymbl: null,
            area: '서울',
            entpAddr: '서울 성동구 마장로 260 ',
            entpAddr1: '서울 성동구 마장로 260',
            entpAddr2: null,
            taxNo: null,
          },
          {
            no: '4',
            bcncUnityCompanySeqs: '108177421',
            bcncCode: '1082049710000105',
            companyName: '메디홀스',
            bossName: '김보배',
            bcncCobFlagCodeNm: '판매(임대)업',
            bcncCobDetailName: '의료기기',
            hptlSymbl: null,
            area: '대구',
            entpAddr: '대구광역시 중구 국채보상로142길 11 (동인동4가) ',
            entpAddr1: '대구광역시 중구 국채보상로142길 11 (동인동4가)',
            entpAddr2: null,
            taxNo: '504-23-38972',
          },
          {
            no: '5',
            bcncUnityCompanySeqs: '123565791',
            bcncCode: '1082049710000104',
            companyName: 'H&Y메디케어',
            bossName: '이선정',
            bcncCobFlagCodeNm: '판매(임대)업',
            bcncCobDetailName: '의료기기',
            hptlSymbl: null,
            area: '경기',
            entpAddr:
              '경기도 고양시 덕양구 꽃마을로 66, 한일미디어타워 409호 (향동동) ',
            entpAddr1:
              '경기도 고양시 덕양구 꽃마을로 66, 한일미디어타워 409호 (향동동)',
            entpAddr2: null,
            taxNo: '504-40-01472',
          },
          {
            no: '6',
            bcncUnityCompanySeqs: '157253130',
            bcncCode: '1082049710000103',
            companyName: '주식회사 에스씨에이치홀딩스',
            bossName: '박호국',
            bcncCobFlagCodeNm: '판매(임대)업',
            bcncCobDetailName: '의료기기',
            hptlSymbl: null,
            area: '경기',
            entpAddr:
              '경기도 시흥시 역전로 15, 산경빌딩 지하층 B01호 (정왕동) ',
            entpAddr1:
              '경기도 시흥시 역전로 15, 산경빌딩 지하층 B01호 (정왕동)',
            entpAddr2: null,
            taxNo: '749-81-00628',
          },
          {
            no: '7',
            bcncUnityCompanySeqs: '108179180',
            bcncCode: '1082049710000102',
            companyName: '주식회사 굿메드',
            bossName: '안경모',
            bcncCobFlagCodeNm: '판매(임대)업',
            bcncCobDetailName: '의료기기',
            hptlSymbl: null,
            area: '경기',
            entpAddr: '경기도 남양주시 가운로1길 1, 드림프라자 504호 (다산동) ',
            entpAddr1: '경기도 남양주시 가운로1길 1, 드림프라자 504호 (다산동)',
            entpAddr2: null,
            taxNo: '331-86-00548',
          },
          {
            no: '8',
            bcncUnityCompanySeqs: '108195157',
            bcncCode: '1082049710000101',
            companyName: '주식회사 와이지메디칼',
            bossName: '강승구',
            bcncCobFlagCodeNm: '판매(임대)업',
            bcncCobDetailName: '의료기기',
            hptlSymbl: null,
            area: '경기',
            entpAddr:
              '경기도 시흥시 은계로 260, 우평 라비엔빌딩 509호 (은행동) ',
            entpAddr1:
              '경기도 시흥시 은계로 260, 우평 라비엔빌딩 509호 (은행동)',
            entpAddr2: null,
            taxNo: '256-87-02539',
          },
          {
            no: '9',
            bcncUnityCompanySeqs: '108190857',
            bcncCode: '1082049710000100',
            companyName: '서울의지',
            bossName: '선동윤',
            bcncCobFlagCodeNm: '판매(임대)업',
            bcncCobDetailName: '의료기기',
            hptlSymbl: null,
            area: '서울',
            entpAddr: '서울특별시 용산구 한강대로15길 2-9 (한강로3가) ',
            entpAddr1: '서울특별시 용산구 한강대로15길 2-9 (한강로3가)',
            entpAddr2: null,
            taxNo: '106-09-68975',
          },
          {
            no: '10',
            bcncUnityCompanySeqs: '108074912',
            bcncCode: '1082049710000099',
            companyName: '한림MS(주)',
            bossName: '김정진',
            bcncCobFlagCodeNm: '판매(임대)업',
            bcncCobDetailName: '일반종합도매',
            hptlSymbl: null,
            area: '경기',
            entpAddr: '경기도 용인시 처인구 영문로 2-27 (유방동) ',
            entpAddr1: '경기도 용인시 처인구 영문로 2-27 (유방동)',
            entpAddr2: null,
            taxNo: '142-81-17070',
          },
        ],
      },
      error: null,
    }
  }

  return await supabase.functions.invoke('getCompanyInfoBcnc', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

export const getSupplyInfoReports = async (
  userId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
) => {
  if (isDemo(userId)) {
    const data = JSON.parse(localStorage.getItem(SUPPLY_INFO_REPORTS) ?? '')
    return { data, error: null }
  }

  return await supabase.functions.invoke('getSupplyInfoReports', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

export const getReportStars = async () => {
  const { data, error } = await supabase
    .from('remote_config')
    .select('*')
    .eq('is_deleted', false)
    .eq('key', 'reportDataRegstPrefills')
    .limit(1)

  if (data && data.length > 0) {
    const value = JSON.parse(data[0].value)

    return {
      data: value,
      error: null,
      canUpdate: true,
    }
  }

  return {
    data: [],
    error,
    canUpdate: false,
  }
}

export const postReportStar = async (userId: string, value: object) => {
  const {
    data: reportReportStars = [],
    error,
    canUpdate,
  } = await getReportStars()

  if (error) {
    return { error }
  }

  if (canUpdate) {
    return await supabase
      .from('remote_config')
      .update({
        updated_at: new Date().toISOString(),
        value: JSON.stringify([value, ...reportReportStars]),
      })
      .eq('is_deleted', false)
      .eq('user_id', userId)
      .eq('key', 'reportDataRegstPrefills')
      .select()
  }

  return await supabase
    .from('remote_config')
    .insert([
      {
        user_id: userId,
        key: 'reportDataRegstPrefills',
        value: JSON.stringify([value]),
        type: 'json',
      },
    ])
    .select()
}

export const deleteReportStar = async (userId: string, id: string) => {
  const { data: reportReportStars = [], error } = await getReportStars()

  if (error) {
    return { error }
  }

  const removedReportStars = reportReportStars.filter(
    (item: { id: string }) => item.id !== id,
  )

  return await supabase
    .from('remote_config')
    .update({
      updated_at: new Date().toISOString(),
      value: JSON.stringify(removedReportStars),
    })
    .eq('is_deleted', false)
    .eq('user_id', userId)
    .eq('key', 'reportDataRegstPrefills')
    .select()
}

export const getSupplyInfoReportSummary = async (
  userId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any,
) => {
  if (isDemo(userId)) {
    const data = JSON.parse(
      localStorage.getItem(SUPPLY_INFO_REPORT_SUMMARY) ?? '',
    )
    console.log('data', data)

    return { data, error: null }
  }

  const { data, error } = await supabase.functions.invoke(
    'getSupplyInfoReportSummary',
    {
      headers: {
        ...(window.__APP_DEV__ === 'production'
          ? { ClientKey: productionClientKey }
          : {}),
      },
      body: params,
    },
  )

  if (error) {
    return { error }
  }

  return {
    data,
    error: null,
  }
}

export const deleteCompanyInfoBcnc = async (
  userId: string,
  params: {
    bcncCode: number
  },
) => {
  return await supabase.functions.invoke('deleteCompanyInfoBcnc', {
    headers: {
      ...(window.__APP_DEV__ === 'production'
        ? { ClientKey: productionClientKey }
        : {}),
    },
    body: params,
  })
}

const isDemo = (userId: string) => {
  return userId === androidDemoId || userId === iosDemoId
}

const setDemoInitialData = () => {
  localStorage.setItem(
    SUPPLY_INFO_MANAGES,
    JSON.stringify({
      page: 1,
      pageSize: 10,
      totalElements: 1,
      items: [
        {
          suplyContSeq: Math.random(),
          suplyDate: '20190912',
          itemName: '탄력밴드',
          dvyfgEntpName: 'SEJONG hospital',
          grade: '1',
          suplyQty: '1000',
          suplyAmt: '100000',
        },
      ],
    }),
  )

  localStorage.setItem(
    SUPPLY_INFO_REPORTS,
    JSON.stringify({
      page: 1,
      pageSize: 10,
      totalElements: 1,
      items: [
        {
          meaClassNo: 'B03100.02',
          no: 1,
          suplyEntpName: '(주)네오메드',
          suplyAmt: '68265',
          suplyFlagCode: '1',
          suplyTypeCode: '2',
          typeName: 'AR-1924B',
          isTraceManageTarget: false,
          manufYm: '130202',
          permitNo: '수허 10-1038 호',
          itemSeq: 'XYZ456789012345678',
          useTmlmt: '200202',
          bcncEntpName: '개발팀',
          itemName: '특수재질골절합용나사',
          suplyQty: '555',
          indvdlzSuplyQty: '12',
          lotNo: 'LOT123456789012345',
          bcncCobTypeName: '판매(임대)업',
          suplyUntpc: '123',
          udiDiCode: '+4312312',
          cobTypeName: '제조업',
          suplyDate: '20190813',
        },
      ],
    }),
  )

  localStorage.setItem(
    SUPPLY_INFO_REPORT_SUMMARY,
    JSON.stringify({
      items: [
        {
          lastReportTime: '2022-06-08 01:36:15',
          reportCnt: 1,
          suplyCnt: 455,
          grade2Cnt: 0,
          reportStatus: '보고취소',
          grade1Cnt: 0,
          grade3Cnt: 455,
          grade4Cnt: 0,
          suplyContStdmt: '202205',
          lastReportDateLimit: '2022-06-30',
        },
        {
          lastReportTime: '2022-05-07 01:35:45',
          reportCnt: 1,
          suplyCnt: 396,
          grade2Cnt: 0,
          reportStatus: '보고',
          grade1Cnt: 0,
          grade3Cnt: 396,
          grade4Cnt: 0,
          suplyContStdmt: '202204',
          lastReportDateLimit: '2022-05-31',
        },
        {
          lastReportTime: '2022-01-02 01:35:45',
          reportCnt: 1,
          suplyCnt: 396,
          grade2Cnt: 0,
          reportStatus: '미보고',
          grade1Cnt: 0,
          grade3Cnt: 396,
          grade4Cnt: 0,
          suplyContStdmt: '202204',
          lastReportDateLimit: '2022-01-31',
        },
      ],
    }),
  )
}
