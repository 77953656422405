import { Select } from 'antd'
import { StyledFormItem } from 'pages/ReportRegisterPage'
import React from 'react'

type ValuesType = {
  label: string
  value: string
}

type CustomSelectProps = {
  label: string
  value: string
  valueList: ValuesType[]
  onClick: (value: string) => void
  validateStatus:
    | ''
    | 'error'
    | 'success'
    | 'warning'
    | 'validating'
    | undefined
}

const CustomSelect = ({
  valueList,
  label,
  onClick,
  validateStatus,
  value,
}: CustomSelectProps) => {
  return (
    <StyledFormItem validateStatus={validateStatus} label={label}>
      <Select
        allowClear
        onChange={(value) => {
          onClick(value)
        }}
        value={value}
      >
        {valueList.map((item) => (
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    </StyledFormItem>
  )
}

export default React.memo(CustomSelect)
