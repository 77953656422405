import { ArrowLeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
  title?: string
  companyName?: string
  onClickBackButton?: () => void
}

const Header: React.FC<Props> = ({ title, companyName, onClickBackButton }) => {
  const navigate = useNavigate()

  return (
    <StyledHeader>
      <StyledLeft>
        {onClickBackButton ? (
          <ArrowLeftOutlined
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              height: '100%',
            }}
            onClick={onClickBackButton}
          />
        ) : (
          <img src="/logo.png" style={{ height: '60%', marginLeft: '16px' }} />
        )}
        <StyledTitle level={5}>{title}</StyledTitle>
      </StyledLeft>
      {companyName && (
        <StyledCompanyName onClick={() => navigate('/setting')} strong>
          {companyName}
        </StyledCompanyName>
      )}
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-bottom: 1px solid #f0f0f0;
  z-index: 999;
`

const StyledLeft = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const StyledTitle = styled(Typography.Title)`
  margin: 0 0 0 0 !important;
`

const StyledCompanyName = styled(Typography.Text)`
  cursor: pointer;
  margin-right: 16px;
`

export default Header
