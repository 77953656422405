export const suplyFlags = [
  { label: '출고', value: '1' },
  { label: '반품', value: '2' },
  { label: '폐기', value: '3' },
  { label: '임대', value: '4' },
  { label: '회수', value: '5' },
]

export const suplyTypes = [
  { label: '제조ㆍ수입ㆍ판매(임대)에 공급', value: '1' },
  { label: '의료기관에 공급', value: '2' },
  { label: '약국개설자 또는 의약품도매상에 공급', value: '3' },
  { label: '견본품, 기부용 또는 군납용 등으로 공급', value: '4' },
]

export const getInitialForm = (suplyContStdmt: string) => {
  return {
    suplyFlagCode: '',
    suplyTypeCode: '',
    suplyContStdmt: suplyContStdmt,
    stdCode: '',
    suplyDate: '',
    suplyQty: '1',
    indvdlzSuplyQty: '',
    suplyUntpc: '0',
    bcncCode: '',
    isDiffDvyfg: false,
    dvyfgPlaceBcncCode: '',
    suplyAmt: '',
    remark: '',
  }
}

export interface ReportForm {
  suplyFlagCode: string
  suplyTypeCode: string
  suplyContStdmt: string
  stdCode: string
  suplyDate: string
  suplyQty: string
  indvdlzSuplyQty: string
  suplyUntpc: string
  bcncCode: string
  isDiffDvyfg: boolean
  dvyfgPlaceBcncCode: string
  suplyAmt: string
  remark: string

  lotNo?: string
  itemSeq?: string
  manufYm?: string
  useTmlmt?: string
  itemName?: string
}

export const initialSupplyInforManageResponse = {
  page: 0,
  pageSize: 0,
  totalElements: 0,
  items: [],
}
