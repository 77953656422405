import { App, Button, DatePicker, Input, List } from 'antd'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import React, { useContext, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AppContext } from '../components/AppContext'
import Header from '../components/Header'
import PageContainer from '../components/PageContainer'
import { GetSupplyInfoReportResponse } from '../models/interfaces'
import { getSupplyInfoManages } from '../service/repository'

interface Props {}

const SupplyListPage: React.FC<Props> = () => {
  const { message } = App.useApp()
  const navigate = useNavigate()
  const { user } = useContext(AppContext)

  const [offset, setOffset] = useState(1)
  const [searchSuplyStdmt, setSearchSuplyStdmt] = useState<Date | null>(null)
  const [bcncName, setBcncName] = useState<string>('')
  const [response, setResponse] = React.useState<GetSupplyInfoReportResponse>({
    page: 0,
    pageSize: 0,
    totalElements: 0,
    items: [],
  })

  const loadReports = async (offset: number) => {
    if (!searchSuplyStdmt) return

    const { data, error } = await getSupplyInfoManages(user.id, {
      suplyContStdmt: format(searchSuplyStdmt, 'yyyyMM'),
      bcncName,
      offset,
    })

    if (error) {
      setResponse({
        page: 0,
        pageSize: 0,
        totalElements: 0,
        items: [],
      })

      if (error) {
        const errorResponse = await error.context.json()
        if (errorResponse?.fromUdiSystem?.message) {
          message.error('Failed: ' + errorResponse.fromUdiSystem.message)
          return
        }

        message.error('Failed: ' + error.message)
        return
      }
    }

    setOffset(offset)
    if (offset === 1) {
      setResponse(data)
    } else {
      setResponse({
        ...data,
        items: [...response.items, ...data.items],
      })
    }
  }

  return (
    <React.Fragment>
      <Header
        onClickBackButton={() => navigate('/')}
        title="업체별 보고자료 조회"
        companyName={user.companyName}
      />
      <PageContainer>
        <div>
          업체명
          <Input
            onChange={(e) => {
              setBcncName(e.target.value)
            }}
          />
        </div>
        <StyledTopContainer>
          공급기준 월
          <StyledDatePicker
            onChange={(date) => {
              if (!date) return
              setSearchSuplyStdmt(date.toDate())
            }}
            picker="month"
            value={searchSuplyStdmt ? dayjs(searchSuplyStdmt) : null}
          />
        </StyledTopContainer>
        <div>
          <Button
            onClick={() => {
              loadReports(1)
            }}
            block
          >
            조회
          </Button>
        </div>
        <StyledListContainer>
          <InfiniteScroll
            dataLength={response.items.length}
            next={() => {
              loadReports(offset + 1)
            }}
            hasMore={response.totalElements > response.items.length}
            loader={<StyledListText>Loading...</StyledListText>}
          >
            <List
              header={
                <div>
                  <b>공급일자 - 제품명</b>
                  <div>업체 | 수량 | 금액</div>
                </div>
              }
              dataSource={response.items}
              renderItem={(item) => (
                <StyledListItem key={item.no}>
                  <div>
                    <b>
                      {item.suplyDate} -{item.itemName}
                    </b>
                    <div>
                      {item.suplyEntpName} | 등급 | {item.suplyQty} |{' '}
                      {item.suplyAmt}
                    </div>
                  </div>
                </StyledListItem>
              )}
            >
              {response.items.length === 0 && (
                <StyledListText>해당 월의 공급내역 없음</StyledListText>
              )}
            </List>
          </InfiniteScroll>
        </StyledListContainer>
      </PageContainer>
    </React.Fragment>
  )
}

const StyledListText = styled.div`
  text-align: center;
  margin: 16px 0;
`

const StyledListContainer = styled.div`
  margin-top: 16px;

  & .ant-list-header {
    background-color: #f0f0f0;
    padding: 8px;
  }

  & .ant-list-item {
    padding: 8px;
  }
`

const StyledListItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledTopContainer = styled.div`
  margin: 8px 0;
`

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export default SupplyListPage
