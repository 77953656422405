/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import UdiInputModal from 'components/UdiInputModal'
import React, { Dispatch, SetStateAction, useState } from 'react'
import styled from 'styled-components'
import { ReportForm } from './data'

type ScanInputProps = {
  form: ReportForm
  setForm: Dispatch<SetStateAction<ReportForm>>
  openCamera: (typed: 'scandit' | 'vision-camera') => void
  handleInput: (code: string) => Promise<string | void | null>
  isScanCodeLoading: boolean
  isShowValidation: boolean
}

const ScanInput = ({
  form,
  openCamera,
  setForm,
  isScanCodeLoading,
  isShowValidation,
  handleInput,
}: ScanInputProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const currentCameraName: 'scandit' | 'vision-camera' =
    (process.env.REACT_APP_CAMERA_NAME as 'scandit' | 'vision-camera') ??
    'scandit'

  return (
    <StyledScanContainer
      validateStatus={
        isShowValidation && form.suplyFlagCode === '2' && !form.suplyUntpc
          ? 'error'
          : 'success'
      }
      label="UDI 코드"
    >
      <UdiInputModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onClick={async (code: string) => {
          // 일단 전처리가 안된상태로 입력되었으면 전처리부터 한 뒤, 조회를 실행해야 한다.
          const response = await handleInput(code)
          return response
        }}
        udiCode={form.stdCode}
      />
      <StyledButtons>
        <Button
          block
          loading={!isModalOpen && isScanCodeLoading}
          onClick={async () => {
            // setIsModalOpen(true) // ask modal
            openCamera(currentCameraName)
          }}
          type="primary"
        >
          UDI Scan
        </Button>
        <Button
          block
          loading={isModalOpen && isScanCodeLoading}
          onClick={() => {
            setIsModalOpen(true)
          }}
          type="primary"
        >
          직접 입력
        </Button>
      </StyledButtons>
      <StyledScanInputViewer>
        <TextArea
          disabled
          value={form.stdCode}
          onChange={(e) => setForm({ ...form, stdCode: e.target.value })}
        />
        <Input value={form.itemName} disabled />
      </StyledScanInputViewer>
      {/* <StyledScanInputContainer>
        <Button
          onClick={() => {
            localStorage.setItem('form', JSON.stringify(form))
            updateFormData(form.stdCode)
          }}
          loading={isScanCodeLoading}
        >
          조회
        </Button>
      </StyledScanInputContainer> */}
    </StyledScanContainer>
  )
}

export default React.memo(ScanInput)

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`

const StyledScanContainer = styled(Form.Item)`
  & input {
    margin: 8px 0;
  }
`

const StyledScanInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  textarea {
    flex-grow: 1;
  }

  button {
    margin-left: 8px;
    height: auto;
    align-self: stretch;
  }
`

const StyledScanInputViewer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
`
