/* eslint-disable @typescript-eslint/no-unused-vars */
import { App, Button, List } from 'antd'
import { SupplyInfoManageDto } from 'models/interfaces'
import React, { useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { AppContext } from '../../../components/AppContext'
import { deleteSupplyInfoManages } from '../../../service/repository'

type SupplyListProps = {
  offset: number
  loadSupplyInfoManages: (offset: number) => void
  supplyInfoManages: SupplyInfoManageDto[]
  hasMore: boolean
}

const SupplyList = ({
  offset,
  loadSupplyInfoManages,
  supplyInfoManages,
  hasMore,
}: SupplyListProps) => {
  const { user } = useContext(AppContext)
  const { message } = App.useApp()

  return (
    <StyledListContainer>
      <InfiniteScroll
        scrollableTarget="page-container"
        dataLength={supplyInfoManages.length}
        next={() => loadSupplyInfoManages(offset + 1)}
        hasMore={hasMore}
        loader={<StyledListText>Loading...</StyledListText>}
      >
        <List
          header={
            <div>
              <b>공급일자 - 제품명</b>
              <div>제조&#40;수입&#41; | 등급 | 수량</div>
            </div>
          }
          dataSource={supplyInfoManages}
          renderItem={(item) => (
            <StyledItem key={item.suplyAmt}>
              <Button
                type="primary"
                size="small"
                onClick={async () => {
                  const { error } = await deleteSupplyInfoManages(user.id, {
                    suplyContStdmt: item.suplyDate.slice(0, 6),
                    suplyContSeq: item.suplyContSeq,
                  })

                  if (error) {
                    const errorResponse = await error.context.json()
                    if (errorResponse?.fromUdiSystem?.message) {
                      message.error(
                        'Failed: ' + errorResponse.fromUdiSystem.message,
                      )
                      return
                    }

                    message.error('Failed: ' + error.message)
                    return
                  }

                  message.success('삭제되었습니다.')
                  loadSupplyInfoManages(1)
                }}
              >
                삭제
              </Button>
              <InlineBlock>
                <b>
                  {item.suplyDate} - {item.itemName}
                </b>
                <br />
                {`${item.entpName ?? '-'} | ${item.grade ?? '-'} | ${item.suplyQty}`}
              </InlineBlock>
            </StyledItem>
          )}
        ></List>
        {supplyInfoManages.length === 0 && (
          <StyledListText>대기 중인 보고자료 없음</StyledListText>
        )}
      </InfiniteScroll>
    </StyledListContainer>
  )
}

export default React.memo(SupplyList)

const StyledListText = styled.div`
  text-align: center;
  margin: 16px 0;
`

const StyledListContainer = styled.div`
  margin-top: 16px;

  & .ant-list-header {
    background-color: #f0f0f0;
    padding: 8px;
  }

  & .ant-list-item {
    padding: 8px;
  }
`

const StyledItem = styled(List.Item)`
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 8px;

  & button {
    margin-right: 8px;
  }
`

const InlineBlock = styled.div`
  display: inline-block;
  margin-right: auto;
`
