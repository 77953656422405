export const parseScanData = (data: string) => {
  try {
    return JSON.parse(decodeURIComponent(atob(data)))
  } catch (error) {
    console.error('Error parsing scan data:', error)
    return null
  }
}

export const extractRawStdCode = (codes: Array<{ value: string }>) => {
  const startWith01 = codes.find((code) => code.value.startsWith('01'))
  const otherCodes = codes.filter((code) => !code.value.startsWith('01'))

  const rawStdCode = startWith01
    ? [startWith01, ...otherCodes].reduce((acc, code) => acc + code.value, '')
    : codes.reduce((acc, code) => acc + code.value, '')

  return rawStdCode
}

// 고정길이 데이터를 AI 코드로 구분하여 배열로 반환
export const splitByAICode = (stdCode: string): string[] => {
  const aiCode = stdCode.substring(0, 2)

  let dataLength = 0
  switch (aiCode) {
    case '01':
      dataLength = 14
      break
    case '11':
      dataLength = 6
      break
    case '17':
      dataLength = 6
      break
    // 가변길이 데이터 구분자
    case '21':
    case '10':
      dataLength = stdCode.length - 2
      break
    // 모르는 AI 코드는 무시
    default:
      return []
  }

  const firstData = `${stdCode.substring(0, 2 + dataLength)}`

  const secondData = stdCode.substring(2 + dataLength, stdCode.length)

  if (secondData.length > 0) {
    return [firstData, ...splitByAICode(secondData)]
  } else {
    return [firstData]
  }
}

export const closeCamera = () => {
  window.ReactNativeWebView.postMessage(
    btoa(
      encodeURIComponent(
        JSON.stringify({
          name: 'closeCodeScanner',
          params: {},
        }),
      ),
    ),
  )
}
