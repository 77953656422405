import React, { useContext, useEffect } from 'react'
import Header from '../components/Header'
import styled from 'styled-components'
import { Alert, App, Button } from 'antd'
import { AppContext } from '../components/AppContext'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import supabase from '../service/supabase'

interface Props {}

const MainPage: React.FC<Props> = () => {
  const { user } = useContext(AppContext)
  const navigate = useNavigate()
  const { message } = App.useApp()
  const [isShowAlert, setIsShowAlert] = React.useState(false)

  useEffect(() => {
    if (user.id === '') {
      navigate('/login')
    }
  }, [])

  const goMenuPage = async (href: string) => {
    const { data: profile, error } = await supabase
      .from('profile')
      .select(
        `
        companyName:company_name,
        bizRegNumber:biz_reg_number,
        udiSystemClientId:udi_system_client_id,
        udiSystemClientSecret:udi_system_client_secret
        `,
      )
      .limit(1)
      .single()

    if (error) {
      message.error('Failed: ' + error.message)
      return
    }

    if (!profile.udiSystemClientId) {
      setIsShowAlert(true)
      return
    }

    navigate(href)
  }

  return (
    <>
      <Header companyName={user.companyName} />
      <PageContainer>
        <StyledMainPage>
          <img
            style={{
              bottom: 0,
              right: 0,
              width: '50%',
              position: 'absolute',
            }}
            src="/circle.png"
          />
          <StyledMenuButton
            backgroundColor="#66B49B"
            block
            type="primary"
            onClick={() => goMenuPage('/reports/register')}
          >
            보고자료 등록
          </StyledMenuButton>
          <StyledMenuButton
            block
            backgroundColor="#479B80"
            type="primary"
            onClick={() => goMenuPage('/supplies')}
          >
            업체별 보고자료 조회
          </StyledMenuButton>
          <StyledMenuButton
            block
            backgroundColor="#337F66"
            type="primary"
            onClick={() => goMenuPage('/supplies/report')}
          >
            공급내역 보고
          </StyledMenuButton>
          <StyledMenuButton
            block
            backgroundColor="#136B4D"
            type="primary"
            onClick={() => goMenuPage('/companies')}
          >
            거래처 관리
          </StyledMenuButton>
        </StyledMainPage>
        {isShowAlert && (
          <StyledAlert
            description="Open API 판매인증 ID와 PW를 입력해주세요."
            type="error"
            isShow={isShowAlert}
            action={
              <Button
                size="small"
                danger
                onClick={() => {
                  window.ReactNativeWebView.postMessage(
                    btoa(
                      encodeURIComponent(
                        JSON.stringify({
                          name: 'openWebBrowser',
                          params: {
                            url: 'https://blog.naver.com/optimwork/223423680665',
                            mode: 'external',
                          },
                        }),
                      ),
                    ),
                  )
                }}
              >
                판매인증 ID, PW 확인 방법
              </Button>
            }
          />
        )}
      </PageContainer>
    </>
  )
}

const StyledMainPage = styled.div`
  & > button:not(:last-child) {
    margin-bottom: 16px;
  }
`

const StyledMenuButton = styled(Button)<{ backgroundColor?: string }>`
  font-weight: bold;
  font-size: 16px;
  height: 100px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#479B80'};
`

const StyledAlert = styled(Alert)<{ isShow: boolean }>`
  position: fixed;
  top: 70px;
  left: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`

export default MainPage
