import { Input } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { Dispatch, SetStateAction } from 'react'
import { StyledFormItem } from '..'
import { ReportForm } from './data'

type ExtraInputProps = {
  form: ReportForm
  isShowValidation: boolean
  setForm: Dispatch<SetStateAction<ReportForm>>
}

const ExtraInput = ({ isShowValidation, form, setForm }: ExtraInputProps) => {
  return (
    <React.Fragment>
      <StyledFormItem
        validateStatus={
          isShowValidation &&
          form.suplyFlagCode === '5' &&
          !form.suplyQty &&
          !form.indvdlzSuplyQty
            ? 'error'
            : 'success'
        }
        label="낱개 회수 수량"
      >
        <Input
          value={form.indvdlzSuplyQty}
          type="phone"
          onChange={(e) =>
            setForm({
              ...form,
              indvdlzSuplyQty: e.target.value,
            })
          }
        />
      </StyledFormItem>

      <StyledFormItem
        validateStatus={
          isShowValidation && form.suplyFlagCode === '2' && !form.suplyUntpc
            ? 'error'
            : 'success'
        }
        label="공급단가"
      >
        <Input
          value={form.suplyUntpc}
          type="phone"
          onChange={(e) =>
            setForm({
              ...form,
              suplyUntpc: e.target.value,
              suplyAmt:
                form.suplyTypeCode === '2'
                  ? (Number(e.target.value) * Number(form.suplyQty)).toString()
                  : form.suplyAmt,
            })
          }
        />
      </StyledFormItem>

      <StyledFormItem
        validateStatus={
          isShowValidation && form.suplyFlagCode === '2' && !form.suplyAmt
            ? 'error'
            : 'success'
        }
        label="공급금액"
      >
        <Input
          value={form.suplyAmt}
          type="phone"
          onChange={(e) => setForm({ ...form, suplyAmt: e.target.value })}
          disabled={form.suplyFlagCode !== '2' && form.suplyTypeCode === '2'}
        />
      </StyledFormItem>

      <StyledFormItem label="비고">
        <TextArea
          value={form.remark}
          onChange={(e) => setForm({ ...form, remark: e.target.value })}
        />
      </StyledFormItem>
    </React.Fragment>
  )
}

export default ExtraInput
