import React from 'react'

import Router from './Router'
import AntdThemeRegistry from './components/AntdThemeRegistry'
import { App as AntdApp } from 'antd'
import AppProvider from './components/AppContext'

function App() {
  return (
    <AppProvider>
      <AntdThemeRegistry>
        <AntdApp>
          <Router />
        </AntdApp>
      </AntdThemeRegistry>
    </AppProvider>
  )
}

export default App
