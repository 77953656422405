import { Button as AntdButton, ButtonProps } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledButton: React.FC<ButtonProps & React.RefAttributes<HTMLElement>> = (
  props,
) => {
  return <Button {...props} />
}

const Button = styled(AntdButton)`
  height: 44px;
  font-weight: bold;
`

export default StyledButton
