import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import styled from 'styled-components'
import TextArea from 'antd/es/input/TextArea'
import { CloseOutlined } from '@ant-design/icons'

type CustomModalProps = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  onClick: (code: string) => Promise<string | null | void>
  udiCode: string
}

const UdiInputModal = ({
  isOpen,
  setIsOpen,
  onClick,
  udiCode,
}: CustomModalProps) => {
  const [code, setCode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errText, setErrText] = useState<string>('')

  useEffect(() => {
    if (isOpen) {
      setCode(udiCode)
      setErrText('')
    }
  }, [udiCode, isOpen])

  return (
    <Modal
      title="UDI 입력"
      open={isOpen}
      footer={null}
      width="90%"
      closeIcon={false}
      onCancel={() => setIsOpen(false)}
    >
      <ModalContainer>
        <p>UDI를 직접 입력해주세요</p>
        <div className="textbox">
          <TextArea
            value={code}
            onChange={(e) => setCode(e.currentTarget.value)}
          />
          {code.length > 0 && (
            <span
              className="close"
              onClick={() => {
                setCode('')
              }}
            >
              <CloseOutlined />
            </span>
          )}
        </div>
        {errText && <div className="err">{errText}</div>}
        <div className="bottom">
          <Button
            block
            type="text"
            onClick={() => {
              setCode('')
              setIsOpen(false)
            }}
          >
            취소
          </Button>
          <Button
            block
            loading={isLoading}
            type="primary"
            onClick={async () => {
              setIsLoading(true)
              const res = await onClick(code)
              if (res) {
                setErrText(res)
              } else {
                setErrText('')
                setIsOpen(false)
              }
              setIsLoading(false)
            }}
          >
            확인
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  )
}

export default React.memo(UdiInputModal)

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  p {
    margin-bottom: 20px;
  }

  .textbox {
    position: relative;

    .close {
      position: absolute;
      top: 2px;
      right: 6px;

      color: gray;
    }
  }

  .err {
    color: red;
    margin: 1px 0px 0px 1px;
    font-size: 0.95em;
  }

  .bottom {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    justify-content: end;

    button {
      width: 50%;
      height: 40px;
      margin-left: 6px;
      font-weight: 600;
    }
  }
`
