import { App, Typography } from 'antd'
import React, { useContext } from 'react'
import styled from 'styled-components'
import StyledInput from '../components/StyledInput'
import StyledButton from '../components/StyledButton'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext, User } from '../components/AppContext'
import { login } from '../service/repository'

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const { message } = App.useApp()
  const { updateUser } = useContext(AppContext)
  const navigate = useNavigate()

  const [id, setId] = React.useState('')
  const [password, setPassword] = React.useState('')

  const onClickLoginButton = async () => {
    const { data, error } = await login(id, password)

    if (error) {
      message.error('Failed: ' + error.message)
      return
    }

    afterLogin({
      id: data.user.id ?? '',
      companyName: data.user.user_metadata.companyName ?? '',
    })
  }

  const afterLogin = ({ id, companyName }: User) => {
    updateUser({
      id,
      companyName,
    })
    navigate('/')
  }

  return (
    <StyledLoginPage>
      <StyledImage src="/logo.png" />
      <Typography.Title level={1} style={{ color: '#514E50', fontWeight: 500 }}>
        <span className="bold">UDI</span> SYSTEM
      </Typography.Title>
      <StyledLoginWrapper>
        <StyledFormWrapper>
          <StyledInput
            value={id}
            placeholder="아이디"
            onChange={(e) => {
              setId(e.target.value)
            }}
          />
          <StyledInput
            value={password}
            type="password"
            placeholder="비밀번호"
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <StyledButton
            disabled={!id || !password}
            type="primary"
            block
            onClick={onClickLoginButton}
            style={{ border: 'none' }}
          >
            로그인
          </StyledButton>
        </StyledFormWrapper>
        <StyledSignupWrapper>
          <Link to="/signup">
            <Typography.Text>회원가입</Typography.Text>
          </Link>
        </StyledSignupWrapper>
      </StyledLoginWrapper>
    </StyledLoginPage>
  )
}

const StyledLoginPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  height: calc(100vh);

  .bold {
    font-weight: 800;
  }
`

const StyledImage = styled.img`
  width: 120px;
`

const StyledLoginWrapper = styled.div`
  padding: 0 32px;
  margin-top: 80px;
  text-align: center;
`

const StyledFormWrapper = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`

const StyledSignupWrapper = styled.div`
  margin-top: 8px;

  & > a {
    text-decoration: none;
    color: black;
  }
`

export default LoginPage
