import { ConfigProvider, theme } from 'antd'
import React, { PropsWithChildren, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

const AntdThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { token } = theme.useToken()
  const styledComponentTheme = useMemo(() => {
    return { antd: token }
  }, [token])

  return <ThemeProvider theme={styledComponentTheme}>{children}</ThemeProvider>
}

const AntdThemeRegistry: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryBg: '#fff2ee',
          colorPrimary: '#0F7851',
          colorPrimaryBorder: '#0F7851',
          colorPrimaryBorderHover: '#0F7851',
          colorLink: '#0F7851',
          colorLinkHover: '#0F7851',
          colorLinkActive: '#0F7851',
          colorPrimaryTextActive: '#0F7851',
          colorPrimaryText: '#524E4F',
          colorPrimaryTextHover: '#0F7851',
        },
      }}
    >
      <AntdThemeProvider>{children}</AntdThemeProvider>
    </ConfigProvider>
  )
}

export default AntdThemeRegistry
