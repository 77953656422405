export const getInitialForm = () => {
  return {
    suplyFlagCode: '',
    suplyTypeCode: '',
    bcncCode: '',
    isDiffDvyfg: false,
    dvyfgPlaceBcncCode: '',
  }
}

export interface ReportStarForm {
  suplyFlagCode: string
  suplyTypeCode: string
  bcncCode: string
  isDiffDvyfg: boolean
  dvyfgPlaceBcncCode: string
}
