import ReportStarRegisterPage from 'pages/ReportStarRegisterPage'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CompnayPage from './pages/CompanyPage'
import CompanyRegisterPage from './pages/CompanyRegisterPage'
import LoginPage from './pages/LoginPage'
import MainPage from './pages/MainPage'
import PasswordEditPage from './pages/PasswordEditPage'
import ProfileEditPage from './pages/ProfileEditPage'
import ReportRegisterPage from './pages/ReportRegisterPage'
import SettingPage from './pages/SettingPage'
import SignupPage from './pages/SignupPage'
import SupplyListPage from './pages/SupplyListPage'
import SupplyReportPage from './pages/SupplyReportPage'

const Router = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/reports/register" element={<ReportRegisterPage />} />
          <Route
            path="/reports/register/new"
            element={<ReportStarRegisterPage />}
          />
          <Route path="/companies" element={<CompnayPage />} />
          <Route path="/companies/register" element={<CompanyRegisterPage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/setting/profile" element={<ProfileEditPage />} />
          <Route path="/setting/password" element={<PasswordEditPage />} />
          <Route path="/supplies" element={<SupplyListPage />} />
          <Route path="/supplies/report" element={<SupplyReportPage />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  )
}

export default Router
