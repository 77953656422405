import React, { useContext, useState } from 'react'
import Header from '../components/Header'
import { AppContext } from '../components/AppContext'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../components/PageContainer'
import { App, Button, Form, Input, List, Select } from 'antd'
import supabase, { productionClientKey } from '../service/supabase'
import { GetCompnayInfoEntrpResponse } from '../models/interfaces'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'
import { Option } from 'antd/es/mentions'

interface Props {}

interface Form {
  entpName?: string
  brno?: string
  cobFlagCode?: number
  rprsvNm?: string
  area?: number
}

const CompnayRegisterPage: React.FC<Props> = () => {
  const { message } = App.useApp()
  const { user } = useContext(AppContext)
  const navigate = useNavigate()

  const [offset, setOffset] = useState(1)

  const [getCompanyInfoEntrpResponse, setGetCompanyInfoBcncResponse] =
    useState<GetCompnayInfoEntrpResponse>({
      page: 0,
      pageSize: 0,
      totalElements: 0,
      items: [],
    })
  const [form, setForm] = useState<Form>({})
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([])
  const [isShowValidation, setIsShowValidation] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const cobFlags = [
    {
      label: '제조',
      value: '1',
    },
    {
      label: '수입',
      value: '2',
    },
    {
      label: '수리',
      value: '3',
    },
    {
      label: '판매(임대)',
      value: '9',
    },
    {
      label: '요양기관',
      value: '10',
    },
    {
      label: '기타',
      value: '99',
    },
  ]

  const areas = [
    {
      label: '서울',
      value: '1',
    },
    {
      label: '경기',
      value: '2',
    },
    {
      label: '강원',
      value: '3',
    },
    {
      label: '충청북도',
      value: '4',
    },
    {
      label: '충청남도',
      value: '5',
    },
    {
      label: '인천',
      value: '6',
    },
    {
      label: '세종',
      value: '7',
    },
    {
      label: '대전',
      value: '8',
    },
    {
      label: '광주',
      value: '9',
    },
    {
      label: '전라남도',
      value: '10',
    },
    {
      label: '전라북도',
      value: '11',
    },
    {
      label: '경상북도',
      value: '12',
    },
    {
      label: '경상남도',
      value: '13',
    },
    {
      label: '대구',
      value: '14',
    },
    {
      label: '울산',
      value: '15',
    },
    {
      label: '부산',
      value: '16',
    },
    {
      label: '제주',
      value: '17',
    },
  ]

  const loadSupplyInfoManages = async (offset: number) => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    const { data, error } = await supabase.functions.invoke(
      'getCompanyInfoEntrps',
      {
        headers: {
          ...(window.__APP_DEV__ === 'production'
            ? { ClientKey: productionClientKey }
            : {}),
        },
        body: {
          offset,
          ...form,
        },
      },
    )

    setIsLoading(false)

    if (error) {
      const errorResponse = await error.context.json()
      if (errorResponse?.fromUdiSystem?.message) {
        message.error('Failed: ' + errorResponse.fromUdiSystem.message)
        return
      }

      message.error('Failed: ' + error.message)
      return
    }

    setOffset(offset)
    if (offset === 1) {
      setGetCompanyInfoBcncResponse(data)
    } else {
      setGetCompanyInfoBcncResponse({
        ...data,
        items: [...getCompanyInfoEntrpResponse.items, ...data.items],
      })
    }
  }

  return (
    <>
      <Header
        onClickBackButton={() => navigate('/companies')}
        title="거래처 추가"
        companyName={user.companyName}
      />
      <PageContainer>
        <StyledCard>
          <Form layout="vertical">
            <StyledFormItem
              label="거래처명"
              validateStatus={
                isShowValidation && !form.entpName ? 'error' : 'success'
              }
            >
              <Input
                value={form.entpName}
                onChange={(e) => {
                  setSelectedCompanies([])
                  setForm({ ...form, entpName: e.target.value })
                }}
              />
            </StyledFormItem>

            <StyledFormItem
              label="업종"
              validateStatus={
                isShowValidation && !form.cobFlagCode ? 'error' : 'success'
              }
            >
              <Select
                allowClear
                onChange={(value) => {
                  setSelectedCompanies([])
                  setForm({
                    ...form,
                    cobFlagCode: value,
                  })
                }}
              >
                {cobFlags.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </StyledFormItem>

            <StyledFormItem label="사업자등록번호">
              <Input
                value={form.brno}
                onChange={(e) => {
                  setSelectedCompanies([])
                  setForm({ ...form, brno: e.target.value })
                }}
              />
            </StyledFormItem>

            <StyledFormItem label="대표자명">
              <Input
                value={form.rprsvNm}
                onChange={(e) => {
                  setSelectedCompanies([])
                  setForm({ ...form, rprsvNm: e.target.value })
                }}
              />
            </StyledFormItem>

            <StyledFormItem label="지역">
              <Select
                allowClear
                onChange={(value) => {
                  setSelectedCompanies([])
                  setForm({
                    ...form,
                    area: value,
                  })
                }}
              >
                {areas.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </StyledFormItem>
          </Form>

          <StyledButtonContainer>
            <Button
              type="primary"
              onClick={async () => {
                setIsShowValidation(true)

                if (!form.entpName || !form.cobFlagCode) {
                  return
                }

                loadSupplyInfoManages(1)
              }}
            >
              검색
            </Button>
          </StyledButtonContainer>
        </StyledCard>

        <StyledListContainer>
          <InfiniteScroll
            scrollableTarget="page-container"
            dataLength={getCompanyInfoEntrpResponse.items.length}
            next={() => {
              loadSupplyInfoManages(offset + 1)
            }}
            hasMore={
              getCompanyInfoEntrpResponse.totalElements >
              getCompanyInfoEntrpResponse.items.length
            }
            loader={<StyledListText>Loading...</StyledListText>}
            scrollThreshold="100px"
          >
            <List
              header={
                <div>
                  <b>업체명 (대표자)</b>
                  <div>사업자번호 (지역)</div>
                </div>
              }
              dataSource={getCompanyInfoEntrpResponse.items}
              renderItem={(item) => (
                <StyledListItem
                  $isSelected={selectedCompanies.includes(item.no)}
                  key={item.no}
                  onClick={() => {
                    if (selectedCompanies.includes(item.no)) {
                      setSelectedCompanies(
                        selectedCompanies.filter((no) => no !== item.no),
                      )
                    } else {
                      setSelectedCompanies([...selectedCompanies, item.no])
                    }
                  }}
                >
                  <div>
                    <b>
                      {item.entpName}
                      {item.rprsntName ? ` (${item.rprsntName})` : ''}
                    </b>
                    <div>
                      {item.taxNo}{' '}
                      {item.entpAddr1
                        ? ` (${item.entpAddr1.split(' ')[0]})`
                        : ''}
                    </div>
                  </div>
                </StyledListItem>
              )}
            >
              {getCompanyInfoEntrpResponse.items.length === 0 && (
                <StyledListText>검색 조건에 맞는 거래처 없음</StyledListText>
              )}
            </List>
          </InfiniteScroll>
        </StyledListContainer>

        <StyledBottomContainer>
          <StyledSubmittButton
            type="primary"
            onClick={async () => {
              if (selectedCompanies.length === 0) {
                message.error('거래처를 선택해주세요')
                return
              }

              const selectedBcncs = getCompanyInfoEntrpResponse.items.filter(
                (item) => selectedCompanies.includes(item.no),
              )

              try {
                for (const bcnc of selectedBcncs) {
                  const { error } = await supabase.functions.invoke(
                    'postCompanyInfoBcnc',
                    {
                      headers: {
                        ...(window.__APP_DEV__ === 'production'
                          ? { ClientKey: productionClientKey }
                          : {}),
                      },
                      body: {
                        cobFlagCode: form.cobFlagCode,
                        taxNo: bcnc.taxNo,
                        companyName: bcnc.entpName,
                        bcncUnityCompanySeq: bcnc.unityCompanySeq,
                        entpAddr1: bcnc.entpAddr1,
                        entpAddr2: bcnc.entpAddr2,
                      },
                    },
                  )

                  if (error) {
                    const errorResponse = await error.context.json()
                    if (errorResponse?.fromUdiSystem?.message) {
                      throw errorResponse.fromUdiSystem.message
                    }

                    message.error('Failed: ' + error.message)
                    return
                  }
                }
              } catch (error) {
                message.error('Failed: ' + error)
                return
              }

              message.success(
                `거래처 ${selectedBcncs.length}개가 등록되었습니다`,
              )
            }}
          >
            {selectedCompanies.length}개 등록
          </StyledSubmittButton>
        </StyledBottomContainer>
      </PageContainer>
    </>
  )
}

const StyledCard = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 16px;
`

const StyledButtonContainer = styled.div`
  text-align: right;
  margin-top: 16px;
`

const StyledSubmittButton = styled(Button)`
  float: right;
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 8px;
`

const StyledListText = styled.div`
  text-align: center;
  margin: 16px 0;
`

const StyledListContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;

  & .ant-list-header {
    background-color: #f0f0f0;
    padding: 8px;
  }

  & .ant-list-item {
    padding: 8px;
  }
`

const StyledListItem = styled(List.Item)<{ $isSelected?: boolean }>`
  background-color: ${({ $isSelected }) => ($isSelected ? '#c09d93' : 'white')};
`

const StyledBottomContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background-color: white;
`

export default CompnayRegisterPage
