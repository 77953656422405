import React, { PropsWithChildren, createContext, useReducer } from 'react'

export interface User {
  id: string
  companyName: string
}

interface ContextType {
  user: User
  updateUser: (user: User) => void
}

type ActionType = {
  type: 'UPDATE_USER'
  user: User
}

const reducer = (state: User, action: ActionType): User => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...action.user,
      }
    default:
      return state
  }
}

const defaultUser = {
  id: '',
  companyName: '',
}

const getInitialState = () => {
  const user = localStorage.getItem('user')
  return user ? JSON.parse(user) : defaultUser
}

export const AppContext = createContext<ContextType>({
  user: getInitialState(),
  updateUser: () => {},
})

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState())

  const updateUser = (user: User) => {
    localStorage.setItem('user', JSON.stringify(user))
    dispatch({ type: 'UPDATE_USER', user })
  }

  return (
    <AppContext.Provider
      value={{
        user: state,
        updateUser: updateUser,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
