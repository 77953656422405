import React, { useContext, useState } from 'react'
import Header from '../components/Header'
import PageContainer from '../components/PageContainer'
import { App, Button, DatePicker, List } from 'antd'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../components/AppContext'
import { GetSupplyInfoReportSummaryResponse } from '../models/interfaces'
import dayjs from 'dayjs'
import { format } from 'date-fns'
import styled from 'styled-components'
import {
  getSupplyInfoReportSummary,
  postSupplyInfoManagesReport,
} from '../service/repository'

interface Props {}

const SupplyReportPage: React.FC<Props> = () => {
  const { message } = App.useApp()
  const navigate = useNavigate()
  const { user } = useContext(AppContext)

  const [startSuplyContStdmt, setStartSuplyContStdmt] = useState<Date | null>(
    null,
  )
  const [endSuplyContStdmt, setEndSuplyContStdmt] = useState<Date | null>(null)
  const [response, setSupplies] = useState<GetSupplyInfoReportSummaryResponse>({
    items: [],
  })

  const loadSuppliesByMonth = async () => {
    if (!startSuplyContStdmt || !endSuplyContStdmt) return

    const { data, error } = await getSupplyInfoReportSummary(user.id, {
      startSuplyContStdmt: format(startSuplyContStdmt, 'yyyyMM'),
      endSuplyContStdmt: format(endSuplyContStdmt, 'yyyyMM'),
    })

    if (error) {
      const errorResponse = await error.context.json()
      if (errorResponse?.fromUdiSystem?.message) {
        message.error('Failed: ' + errorResponse.fromUdiSystem.message)
        return
      }

      message.error('Failed: ' + error.message)
      return
    }

    setSupplies(data)
  }

  return (
    <>
      <Header
        onClickBackButton={() => navigate('/')}
        title="공급내역 보고"
        companyName={user.companyName}
      />
      <PageContainer>
        <StyledFilterContainer>
          <div>공급기간</div>
          <StyledFilterRight>
            <DatePicker
              picker="month"
              onChange={(date) => {
                if (!date) return
                setStartSuplyContStdmt(date.toDate())
              }}
              value={startSuplyContStdmt ? dayjs(startSuplyContStdmt) : null}
            />
            ~
            <DatePicker
              picker="month"
              onChange={(date) => {
                if (!date) return
                setEndSuplyContStdmt(date.toDate())
              }}
              value={endSuplyContStdmt ? dayjs(endSuplyContStdmt) : null}
            />
            <Button
              onClick={() => {
                loadSuppliesByMonth()
              }}
            >
              조회
            </Button>
          </StyledFilterRight>
        </StyledFilterContainer>
        <StyledListContainer>
          <List
            header={
              <StyledListHeader>
                <b>공급 월</b>
                <span>보고상태</span>
                <span>보고건수</span>
                <span>보고</span>
              </StyledListHeader>
            }
            dataSource={response.items}
            renderItem={(item) => (
              <StyledListRow>
                <span>{item.suplyContStdmt}</span>
                <span>{item.reportStatus}</span>
                <span>{item.reportCnt}</span>
                <Button
                  type="primary"
                  danger={item.reportStatus === '보고'}
                  onClick={async () => {
                    const { error } = await postSupplyInfoManagesReport(
                      user.id,
                      {
                        suplyContStdmt: item.suplyContStdmt,
                      },
                    )

                    if (error) {
                      const errorResponse = await error.context.json()
                      if (errorResponse?.fromUdiSystem?.message) {
                        message.error(
                          'Failed: ' + errorResponse.fromUdiSystem.message,
                        )
                        return
                      }

                      message.error('Failed: ' + error.message)
                      return
                    }

                    message.success(
                      `${item.reportStatus === '보고' ? '보고취소' : '보고'}가 완료되었습니다.`,
                    )
                    loadSuppliesByMonth()
                  }}
                >
                  {item.reportStatus === '보고' ? '보고취소' : '보고  '}
                </Button>
              </StyledListRow>
            )}
          >
            {response.items.length === 0 && (
              <StyledListText>해당 월의 공급내역 없음</StyledListText>
            )}
          </List>
        </StyledListContainer>
      </PageContainer>
    </>
  )
}

const StyledListText = styled.div`
  text-align: center;
  margin: 16px 0;
`

const StyledListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  text-align: center;

  & * {
    flex: 1;
  }
`

const StyledListRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px !important;
  text-align: center;

  & * {
    flex: 1;
  }
`

const StyledListContainer = styled(List)`
  margin-top: 16px;

  & .ant-list-header {
    background-color: #f0f0f0;
    padding: 8px;
  }
`

const StyledFilterRight = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledFilterContainer = styled.div``

export default SupplyReportPage
