import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const PageContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledPageContainer id="page-container">{children}</StyledPageContainer>
  )
}

const StyledPageContainer = styled.div`
  margin-top: 56px;
  padding: 16px;
  overflow: scroll;
  height: calc(100vh - 80px);
`

export default PageContainer
