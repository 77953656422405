import { createClient } from '@supabase/supabase-js'

const supabase = createClient(
  'https://luogmxukxafgfjujgdgf.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx1b2dteHVreGFmZ2ZqdWpnZGdmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY5MzE4MTcsImV4cCI6MjAyMjUwNzgxN30.bSJNjKUSNLY8BZgslNXEBVDWbx4yRleFoJzqw7UiFB0',
)

export const productionClientKey =
  'NTdiMDNmZWNmOTQwYjI0NjRlZWE4OWZlYjM3Y2M2MWI6'

export default supabase
