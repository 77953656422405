import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

type AskDiOnlyCodeModalProps = {
  onCancel: () => void
  onAccept: () => void
}

const AskDiOnlyCodeModal = ({
  onCancel,
  onAccept,
}: AskDiOnlyCodeModalProps) => {
  return (
    <StyledConfirmContainer>
      <div className="box">
        <div>현재 DI 코드만 인식되었습니다.</div>
        <div>DI 코드만 입력하시겠습니까?</div>
        <div className="btns">
          <Button
            type="default"
            onClick={() => {
              onCancel()
            }}
          >
            아니오(다시 스캔)
          </Button>
          <Button type="primary" onClick={() => onAccept()}>
            네
          </Button>
        </div>
      </div>
    </StyledConfirmContainer>
  )
}

export default React.memo(AskDiOnlyCodeModal)

const StyledConfirmContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    padding: 16px;
    width: 80%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.5);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(5px);
    border: 0.5px solid rgba(0, 0, 0, 0.1);

    .btns {
      width: 100%;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      button {
        margin: 0px 4px;
        width: 45%;
      }
    }
  }
`
