import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import StyledInput from '../components/StyledInput'
import StyledButton from '../components/StyledButton'
import styled from 'styled-components'
import { App, Form, Typography } from 'antd'
import supabase from '../service/supabase'
import { AppContext } from '../components/AppContext'

interface Props {}

interface Form {
  companyNumber: string
  companyName: string
  openApiId: string
  openApiPassword: string
}

const ProfileEditPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { message } = App.useApp()
  const { user, updateUser } = useContext(AppContext)

  const [form, setForm] = React.useState<Form>({
    companyNumber: '',
    companyName: '',
    openApiId: '',
    openApiPassword: '',
  })
  const [isShowValidation, setIsShowValidation] = React.useState(false)

  useEffect(() => {
    const loadProfile = async () => {
      const { data: profile, error } = await supabase
        .from('profile')
        .select(
          `companyName:company_name,
            bizRegNumber:biz_reg_number,
            udiSystemClientId:udi_system_client_id,
            udiSystemClientSecret:udi_system_client_secret`,
        )
        .limit(1)
        .single()

      if (error) {
        message.error('Failed: ' + error.message)
        return
      }

      setForm({
        companyNumber: profile.bizRegNumber,
        companyName: profile.companyName,
        openApiId: profile.udiSystemClientId,
        openApiPassword: profile.udiSystemClientSecret,
      })
    }

    loadProfile()
  }, [])

  const onClickSignupButton = async () => {
    setIsShowValidation(true)
    if (
      !form.companyName ||
      !form.companyNumber ||
      !form.openApiId ||
      !form.openApiPassword
    ) {
      return
    }

    const { error } = await supabase
      .from('profile')
      .update({
        company_name: form.companyName,
        biz_reg_number: form.companyNumber,
        udi_system_client_id: form.openApiId,
        udi_system_client_secret: form.openApiPassword,
      })
      .eq('id', user.id)

    if (error) {
      message.error('Failed: ' + error.message)
      return
    }

    message.success('수정이 완료되었습니다.')

    await updateUser({
      id: user.id,
      companyName: form.companyName,
    })
    navigate('/setting')
  }

  return (
    <>
      <Header
        title="정보 수정"
        onClickBackButton={() => {
          navigate('/setting')
        }}
      />

      <StyledSignupPage>
        <Form layout="vertical">
          <Form.Item
            validateStatus={
              isShowValidation && !form.companyName ? 'error' : 'success'
            }
          >
            <StyledInput
              value={form.companyName}
              placeholder="회사명"
              onChange={(e) => {
                setForm({ ...form, companyName: e.target.value })
              }}
            />
          </Form.Item>

          <Form.Item
            validateStatus={
              isShowValidation && !form.companyNumber ? 'error' : 'success'
            }
          >
            <StyledInput
              value={form.companyNumber}
              type="phone"
              placeholder="사업자등록번호"
              onChange={(e) => {
                setForm({ ...form, companyNumber: e.target.value })
              }}
            />
          </Form.Item>

          <Form.Item
            validateStatus={
              isShowValidation && !form.openApiId ? 'error' : 'success'
            }
          >
            <StyledInput
              value={form.openApiId}
              placeholder="판매 인증 ID (OpenAPI)"
              onChange={(e) => {
                setForm({ ...form, openApiId: e.target.value })
              }}
            />
          </Form.Item>

          <Form.Item
            validateStatus={
              isShowValidation && !form.openApiPassword ? 'error' : 'success'
            }
          >
            <StyledInput
              value={form.openApiPassword}
              type="password"
              placeholder="판매 인증 PW (OpenAPI)"
              onChange={(e) => {
                setForm({ ...form, openApiPassword: e.target.value })
              }}
            />
          </Form.Item>
          <StyledInfoWrapper>
            <Typography.Text
              onClick={() => {
                window.ReactNativeWebView.postMessage(
                  btoa(
                    encodeURIComponent(
                      JSON.stringify({
                        name: 'openWebBrowser',
                        params: {
                          url: 'https://blog.naver.com/optimwork/223423680665',
                          mode: 'external',
                        },
                      }),
                    ),
                  ),
                )
              }}
            >
              판매인증 ID, PW 확인 방법
            </Typography.Text>
          </StyledInfoWrapper>

          <StyledButton type="primary" block onClick={onClickSignupButton}>
            정보 수정
          </StyledButton>
        </Form>
      </StyledSignupPage>
    </>
  )
}

const StyledSignupPage = styled.div`
  padding: 32px;
  margin-top: 56px;
  overflow: scroll;
  height: calc(100vh - 80px);
`

const StyledInfoWrapper = styled.div`
  margin: 16px 0;
  text-align: right;

  & > a {
    text-decoration: none;
    color: black;
  }
`

export default ProfileEditPage
